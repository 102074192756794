@import '../../utils/utils.scss';

.content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1% 0;
  .close {
    height: 8%;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  .head {
    width: 100%;
    height: 10%;
    @include typography-primary(1.3em, bold, rgb(15, 50, 77));
    justify-content: center;
  }
  .body {
    width: 100%;
    height: 75%;
    display: flex;
    padding: 0 3%;
    .list {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .listItem:hover {
        background: rgba(15, 50, 77, 0.03);
      }
      .listItem {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        padding: 0 2%;
        .input {
          height: 100%;
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .name {
          @include typography-primary(1em, bold, rgb(15, 50, 77));
          height: 100%;
          width: 50%;
        }
        .default {
          @include typography-primary(1em, normal, #A94064);
          height: 100%;
          width: 20%;
        }
        .options {
          height: 100%;
          width: 10%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  .btns {
    width: 100%;
    margin-top: auto;
    display: flex;
    align-items: center;
    padding: 2%;
    justify-content: right;
    ion-button {
      --box-shadow: 0;
    }
  }
}

.loading {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
