@import '../../utils/utils.scss';

.imgHolder {
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  .input {
    position: absolute;
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    input {
      display: none;
    }
  }
  .Images {
    width: 100%;
    height: 100%;
    .image {
      width: 100%;
      height: 100%;
      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  .tiles {
    position: absolute;
    width: 70%;
    height: 10%;
    display: flex;
    flex-direction: row;
    top: 80%;
    right: 2%;
    overflow: hidden;
    justify-content: end;
    img {
      object-fit: cover;
      width: 20px;
      height: 100%;
      margin: 0 2%;
      cursor: pointer;
      z-index:1;
    }
    .add {
      width: 25px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index:1;
      cursor: pointer;
      ion-icon {
        width: 100%;
        height: 100%;
      }
    }
    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .error{
    width:100%;
    position: absolute;
    height:auto;
    bottom:2%;
     background: rgba(0, 0, 0, 0.1);
     cursor:pointer;
    @include typography-primary(0.8em, bold, #A94064);
    justify-content: center;
  }
}

.remove {
  position: absolute;
  width: 18px;
  height: 18px;
  top: 5%;
  left: 90%;
  border-radius: 50%;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ion-icon {
    width: 100%;
    height: 100%;
  }
}
