.flex {
  display: flex;
  align-items: center;
  padding: 0.6em;
  .img {
    width: 30%;
    height: 100%;
  }
  .text {
    padding: 0 0.5em;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
  }
}
.grid {
  display: flex;
  flex-direction: column;
  align-items: center;
  .img {
    width: 100%;
    height: 60%;
    padding:0.5em;
  }
  .text {
    padding: 0 0.5em;
    width: 100%;
    height: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    
  }
}
