.input {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    position: relative;
    width: 100%;
    height: 100%;
    border: none;
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
