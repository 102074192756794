@import '../../utils//utils.scss';

.container {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0;
  ion-card-content {
    height: 100%;
    padding: 0;
    ion-item {
      display: flex;
      align-items: center;
      width: 100%;
      --padding-start: 10px;
      --inner-padding-end: 0;
      ion-select::part(text) {
        @include typography-primary(unset, bold, var(--ion-color-primary));
      }
      ion-select {
        padding: 0;
        margin-right: 2%;
        width: 100%;
        color: var(--ion-color-primary);
        ion-select-option {
          p {
            @include typography-primary(1em, bold, var(--ion-color-primary));
          }
        }
      }
    }
  }
}
