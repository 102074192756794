@import "../../../utils/utils.scss";
.main {
  position: relative;
  width: 100%;
  padding: 0;
  height: 100%;
  ion-row {
    height: 100%;
    width: 100%;
    ion-col {
      padding: 0 1%;
      height: 100%;
    }
  }
  .content {
    padding: 1%;

    .container {
      padding: 0;
      height: 92vh;
      .holder {
        height: 100%;
        width: 100%;
        overflow-y: scroll;
        padding: 0 0 1em 0;
        .currentStore {
          width: 100%;
          height: 20vh;
          padding: 1% 2%;
        }
        ._storeCard {
          width: 100%;
        }
        .productList {
          width: 100%;
        }
        .offerList {
          width: 100%;
        }
      }
    }
    .storeBar {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.loading {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  .head,
  .content,
  .actions {
    width: 100%;
  }
  .head {
    height: 15%;
    @include typography-primary(1.4em, bold, rgb(15, 50, 77));
    justify-content: center;
  }
  .content {
    height: 70%;
    padding: 0 4%;
    ion-radio-group {
      width: 100%;
      height: 100%;
      .item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 8vh;
        cursor: pointer;
        .label {
          @include typography-primary(1em, bold, rgb(15, 50, 77));
          padding-inline-start: 8px;
        }
        border-bottom: 1px solid rgba(15, 50, 77, 0.316);
      }
    }
    .item:hover {
      background: rgba(15, 50, 77, 0.02);
    }
  }
  .actions {
    height: 15%;
    display: flex;
    justify-content: flex-end;
    padding-inline-end: 5%;
    align-items: center;
  }
}
