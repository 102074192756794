@import '../../utils/utils.scss';

.ProductsCard {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 1% 2%;
  .tabHeader::-webkit-scrollbar {
    display: none !important;
  }
  .tabHeader {
    position: relative;
    width: 100%;
    height: 3em;
    display: flex;
    align-items: center;
    overflow-x: scroll;
    border-bottom: 1px solid #0f324d;
    -ms-overflow-style: none;
    scrollbar-width: none;

    .button {
      height: 100%;
      width: 160px !important;
      @include typography-primary(0.9em, bold, #0f324d);
      margin: 0;
      justify-content: center;
    }
    .selector {
      position: absolute;
      width:160px;
      background: #A94064;
      height: 4px;
      top: 93%;
    }
  }
  .tabs {
    width:100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    min-height:15em;
    justify-content: center;
  }
}
