.wrapper {
	display: flex;
	justify-content: center;

	form {
		background: white;
		padding: 20px 20px 80px 20px;
		margin-bottom: 50px;
		border-radius: 10px;

		.form-title {
			text-align: center;
			font-size: 1.3rem;
			font-weight: bold;
			color: var(--ion-color-primary);
			margin-top: 10px;
		}

		.btn-col {
			padding: 0;
			display: flex;
			justify-content: center;
			ion-button {
				margin-top: 30px;
			}
			.edit-btn {
				color: var(--ion-color-secondary);
			}
		}
	}
	.lg-form {
		width: 50%;
	}
}
