.segmentHolder {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  .grid {
    height: 100%;
    z-index: 1;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .flex {
    height: 100%;
    z-index: 1;
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .drawer {
    position: absolute;
    height: 70%;
    width: 40%;
    border-radius: 7px;
    background: white;
  }
}
