@import '../../utils/utils.scss';

.cont {
  height: 100%;
  width: 100vw;
  overflow: hidden !important;
  --min-width: 100vw;
  --min-height: 100%;
}
.user {
  width: 100%;
  height: 100%;
  padding: 1% 4%;
  .menuBody {
    height: 90%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    .header {
      width: 100%;
      height: 10vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 2% 0;
      .logo {
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
        padding: 2% 0;

        ion-icon {
          height: calc((10vh) * 0.6);
          aspect-ratio: 1/1;
          width: auto;
          margin: 0 5%;
        }
        span {
          @include typography-primary(1.3em, bold, var(--ion-color-primary));
          width: fit-content;
        }
        .span2 {
          color: var(--ion-color-secondary);
          font-style: italic;
        }
      }
      .close {
        position: relative;
        height: 100%;
        aspect-ratio: 1/1.5;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        ion-icon {
          height: 100%;
          aspect-ratio: 1/1;
          width: auto;
        }
      }
    }
    .searchBar {
      width: 96%;
      margin: 4% auto;
      * {
        padding: 1.5%;
      }
      overflow: hidden;
    }
    .links {
      width: 100%;
      height: auto;
      padding: 0 5%;
      .auth {
        width: 100%;
        height: 8vh;
        padding: 4% 0;
        display: flex;
        align-items: center;
        cursor: pointer;
        ion-icon {
          height: 100%;
          aspect-ratio: 1/1;
          width: auto;
          margin-right: 20px;
        }
        .name {
          height: 100%;
          display: flex;
          align-items: center;
          @include typography-primary(1.1em, bold, var(--ion-color-primary));
        }
      }
      .linkGrp {
        width: 100%;
        margin: 1% 0;
        .divider {
          width: 100%;
          height: 8vh;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .labelContainer {
            position: relative;
            height: 100%;
            width: 85%;
            padding: 4.5% 0;
            display: flex;
            align-items: center;
            cursor: pointer;
            ion-icon {
              aspect-ratio: 1/1;
              width: auto;
              height: 100%;
            }
            .title {
              height: 100%;
              display: flex;
              align-items: center;
              @include typography-primary(1.2em, bold, #0f324d);
            }
          }
          .collapseList {
            aspect-ratio: 1/1;
            width: 10%;
            height: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            ion-icon::part('icon-inner') {
              svg {
                fill: var(--ion-color-primary);
              }
            }
            ion-icon {
              aspect-ratio: 1/1;
              width: auto;
              height: 100%;
            }
          }
        }
        .list {
          width: 100%;
          .item {
            width: 85%;
            margin: 0 auto;
            --inner-padding-end: 0;
            --inner-padding-start: 0;
            --inner-padding-bottom: 0;
            --inner-padding-top: 0;
            .label {
              margin: 0;
              font-size: 1rem;
              font-weight: 600;
              color: rgba(15, 50, 77, 0.7);
            }
          }
        }
      }
    }
  }
  .menuFooter {
    height: 10%;
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    @include typography-primary(1em, bold, #0f324d);
  }
}
.seller {
  height: 100%;
  width: 100%;
  overflow-y: hidden !important;
  position: relative;
  .holder {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 5%;
    flex-direction: column;
    justify-content: space-between;
    .header {
      width: 100%;
      height: 10vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 2% 0;
      .logo {
        height: 100%;
        width: auto;
        display: flex;
        align-items: center;
        padding: 2% 0;

        ion-icon {
          height: calc((10vh) * 0.6);
          aspect-ratio: 1/1;
          width: auto;
          margin: 0 5%;
        }
        span {
          @include typography-primary(1.3em, bold, var(--ion-color-primary));
          width: fit-content;
        }
        .span2 {
          color: var(--ion-color-secondary);
          font-style: italic;
        }
      }
      .close {
        position: relative;
        height: 100%;
        aspect-ratio: 1/1.5;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        ion-icon {
          height: 100%;
          aspect-ratio: 1/1;
          width: auto;
        }
      }
    }
    .aux, .main{
       ion-row {
        height: 8vh;
        width: 100%;
        ion-col {
          height: 100%;
          display: flex;
          align-items: center;
          padding: 0;
          .linkholder {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding:4% 0;
            justify-content: flex-start;
            ion-icon {
              height: 100%;
              aspect-ratio: 1/1;
              width: auto;
              margin:0 4%;
            }
            ion-label {
              @include typography-primary(
                1.1em,
                bold,
                var(--ion-color-primary)
              );
            }
          }
        }
      }
    }
    .main {
      width: 100%;
      .userbar {
        height: 8vh;
        border-bottom: 1px solid #0f324d4e;
        margin-bottom: 5px;
        ion-item::part(native) {
          padding-inline-start: 0;
        }
      }
    }
    .aux {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}
