$font: 'Roboto', sans-serif;

.Btn{
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 4vh;
  width: 11vw;
  margin: 0 auto;
  --border-radius: 12px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
    
    }

.desktop{
        width: 100%;
        height: 100%;
        display : flex;
        justify-content : center;
        align-items : center;
      
    section{
            font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
          width:30%;
        }

        h1{
            justify-content: center;
            display: flex;
            align-items: center;
            font-weight: bold;
            color: #0f324d;
           }
        p{
            justify-content: center;
            display: flex;
            align-items: center;
            color: #0f324d;
           }
        
        a{
               text-decoration: none;
           }
        }    