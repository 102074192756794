@import '../../../utils/utils.scss';

.mainContainer {
  width: 100%;
  height: 100%;
  padding: 1%;

  .desktop,
  .mobile {
    width: 100%;
    height: 100%;
    overflow: hidden;
    .desktopgrid,
    .mobileGrid {
      width: 100%;
      height: 100%;
      position: relative;
      padding: 0 3%;
      ion-row {
        width: 100%;
        ion-col {
          height: 100%;
        }
      }
      .headbar {
        ion-col {
          width: 100%;
          .hasStore,
          .noStore {
            width: 100%;
            height: 100%;
            display: flex;
            border-bottom: 1px solid #0f324d90;
          }
          .hasStore {
            justify-content: start;
            align-items: center;
            .profile {
              height: 100%;
              aspect-ratio: 1/1;
              width: auto;
              border-radius: 100%;
              border: 1px solid #0f324d90;
              overflow: hidden;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
            .store {
              height: 100%;
              padding: 0 2%;
              display: flex;
              flex-direction: column;
              align-items: start;
              justify-content: center;
            }
            .btn {
              height: 100%;
              margin-left: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              ion-button {
                width: 100%;
              }
            }
          }
          .noStore {
            padding: 1% 10%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .msg {
              @include typography-primary(1em, bold, #0f324d);
            }
            .btn {
              margin: 25px 0 0 0;
            }
          }
        }
      }
      .content {
      }
    }
  }
  .desktop {
    .desktopgrid {
      .headbar {
        height: 23vh;
        ion-col {
          .hasStore {
            padding: 1% 2%;
            .store {
              width: 30%;
              .name {
                @include typography-primary(2em, bold, #0f324d);
              }
              .type {
                @include typography-primary(1em, solid, #0f324d8d);
                ion-icon {
                  margin: 0 3px;
                }
              }
            }
            .btn {
              width: 20%;
            }
          }
        }
      }
      .content {
        width: 100%;
        height: 68vh;
        padding: 1% 2%;
        .sideMenu {
          .holder {
            display: flex;
            flex-direction: column;
            flex-basis: content;
            padding: 0 0 0 15%;
            .tab:hover {
              background: #0f324d0f;
            }
            .tab {
              width: 100%;
              height: 3em;
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
              ion-icon {
                margin: 0 10px;
              }
              .label {
                @include typography-primary(1.2em, bold, #0f324d);
              }
            }
          }
        }
        .holder {
          height: 100%;
          .tabs {
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }
    }
  }
  .mobile {
    .mobileGrid {
      .headbar {
        height: 15%;
        ion-col {
          .hasStore {
            padding: 3% 2%;
            .store {
              width: 45%;
              .name {
                @include typography-primary(1.1em, bold, #0f324d);
              }
              .type {
                @include typography-primary(0.8em, solid, #0f324d8d);
                // align-items: start;
                ion-icon {
                  margin: 0 3px;
                }
              }
            }
            .btn {
              width: 30%;
            }
          }
        }
      }
      .content {
        width: 100%;
        height: 85%;
        position: relative;
        padding: 1% 0;
        .slideMenu {
          position: absolute;
          height: 100%;
          //width: 100%;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          z-index: 5;
          .list {
            height: 100%;
            width: 80%;
            z-index: 10;
            display: flex;
            flex-direction: column;
            background: white;
            padding: 2%;
            .tab:hover {
              background: #0f324d0f;
            }
            .tab {
              width: 100%;
              height: 3em;
              display: flex;
              align-items: center;
              position: relative;
              cursor: pointer;
              ion-icon {
                margin: 0 10px;
              }
              .label {
                @include typography-primary(1em, bold, #0f324d);
              }
            }
          }
        }
        .tabHolder {
          position: relative;
          width: 85%;
          height: 100%;
          overflow: hidden;
          margin-left: auto;
          .tab {
            position: relative;
            height: 100%;
            width: 100%;
            overflow-y: scroll;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.storetab {
  width: 100%;
  height: 100%;
  position: relative;
  ion-grid {
    width: 100%;
    height: 100%;
    padding: 0 1%;
    overflow-y: hidden;
    ion-row {
      width: 100%;
      ion-col {
        height: 100%;
        width: 100%;
      }
    }
    .storeInfo {
      height: 100%;
      .listcontainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .listheadbar {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 1.5%;
          .label {
            @include typography-primary(1.2em, bold, #0f324d);
            width: auto;
            height: 100%;
          }
          .actions {
            width: 30%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            ion-button {
              height: 100%;
            }
          }
        }
        .storelist {
          width: 100%;
          height: 83%;
          display: flex;
          flex-direction: column;
          justify-content: start;
          align-items: center;
          overflow-y: scroll;
        }
      }
    }
  }
}

.storeCard {
  width: 100%;
  position: relative;
  border: 1px solid #0f324d50;
  border-radius: 15px;
  margin-bottom: 0.5em;

  .optionsBackdrop::before {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: block;
    cursor: default;
    content: ' ';
    background: rgba(15, 50, 77, 0.01);
    grid-column: 1 / 1;
  }
  .optionsBackdrop:focus {
    outline: none;
    box-shadow: none;
  }
  .optionsBackdrop {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .optionsBackdrop {
    .options {
      position: absolute;

      background: white;
      z-index: 200;
      border: 1px solid #0f324d4f;
      border-radius: 5px;
      //box-shadow:rgba(0, 0, 0, 0.10) 0px 3px 1px -2px, rgba(0, 0, 0, 0.10) 0px 2px 2px 0px, rgba(0, 0, 0, 0.10) 0px 1px 5px 0px;
      right: 2%;
      top: 20%;
      .item:hover {
        background: #0f324d1c;
      }
      .item {
        width: 94%;
        height: 40%;
        margin: 1.5% 3%;
        cursor: pointer;
        //border-bottom:1px solid #0f324d6a;
        padding: 0 2%;
        @include typography-primary(1em, bold, #0f324d);
      }
    }
  }

  .grid {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .container {
      width: 100%;
      height: 100%;
      ion-col {
        height: 100%;
        display: flex;
        align-items: center;
      }
      .left {
        .logo {
          aspect-ratio: 1/1;
          border-radius: 50%;
          border: 1px solid #0f324d50;
          overflow: hidden;
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
        .info {
          height: 100%;
          width: 70%;
          display: flex;
          flex-direction: column;
          align-items: start;
          padding: 0 10px;
          .name {
            width: 100%;
            @include typography-primary(1.2em, bold, #0f324d);
          }
          .bio {
            width: 100%;
            height: 80%;
            @include typography-primary(small, 500, #0f324da0);
            align-items: start;
            justify-content: start;
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        align-items: center;

        .options {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          ion-button {
          }
        }
        .info {
        }
      }
    }
  }
}

.accountTab {
  width: 100%;
  height: 100%;
  position: relative;
  overflow-y: scroll;
  padding: 0 2%;
  .profile,
  .actions,
  .info {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(15, 50, 77, 0.5);
    margin: 5px 0;
  }
  .profile {
    height: 30%;
  }
  .info {
    height: auto;
    .secLabel {
      width: 100%;
      height: 2vh;
      @include typography-primary(1em, bold, rgba(15, 50, 77, 1));
      justify-content: start;
    }
    .btns {
      width: 100%;
      height: 8vh;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      .error {
        position: absolute;
        left: 0;
        height: 100%;
        width: 50%;
        @include typography-primary(1em, bold, rgba(235, 71, 79, 1));
      }
      ion-button {
      }
    }
    .item {
      width: 100%;
      height: auto;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .label {
        width: 100%;
        height: 3vh;
        @include typography-primary(1.1em, bold, rgba(15, 50, 77, 1));
        justify-content: start;
      }
      .input {
        width: 100%;
        height: 8vh;
        padding: 1% 5px;
      }
      .field {
        width: 100%;
        height: 8vh;
        padding: 2% 0;
        @include typography-primary(1.1em, normal, rgb(7, 24, 37));
        justify-content: start;
        padding-inline-start: 5px;
      }
    }
  }
  .actions {
    height: 40%;
  }
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
