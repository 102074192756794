$primary-color: #0f324d;

.desktop {

  width: 100vw;
  height: 100vh;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .topBar,
  .container {
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    .cont,
    .sidebar,
    .nav {
      height: 100%;
      padding: 0;
    }
  }

  .topBar {
    height: 10dvh;
    width: 100vw;
    ion-col{
      height:100%;
      width:100%;
    }
  }
  .container {
    height: 90dvh;
    width: 100vw;
    flex-grow: 1;
    .cont {
      display: flex;
      align-items: center;
    }
    .sidebar {
     display: flex;
     justify-content:center;

    }
  }
}
