@import '../../utils/utils.scss';

.backdrop::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: block;
  cursor: default;
  content: '';
  background: transparent;
}
.backdrop:focus {
  outline: none;
  box-shadow: none;
}
.filterby {
  border-radius: 20px;
  margin: 0;
  //background: whitesmoke;
  .content {
    width: 100%;
    height: 100%;
    padding: 0;
    ion-row {
      width: 100%;
      height: 100%;
      ion-col {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5em 1em;
        justify-content: space-between;
        .title {
          position: relative;
          width: fit-content;
          display: flex;
          align-items: center;
          flex-direction: row;
          white-space: nowrap;
          float: left;
          @include typography-primary(1em, bold, #0f324d);
        }
        .selectedList {
          height: 100%;
          width: 60%;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          overflow: hidden;
          align-items: center;

          .selectedItem {
            height: 100%;
            display: flex;
            min-width: 6em;
            margin: 0 3px;
            padding: 5%;
            background: #a94064;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            @include typography-primary(0.9em, bold, white);
          }
        }
        .btn {
          position: relative;
          width: 20%;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
          ion-icon {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}

.dropdown {
  position: absolute !important;
  z-index: 1000;
  padding: 0 5%;
  margin: 0;
  padding: 0;
  top: 10vh;
  width: 325px;
  min-height: 15em;
  background: white;
  border-radius: 10px;
  transition: transform 500ms cubic-bezier(0.12, 0.72, 0.29, 1) 0s,
    -webkit-transform 500ms cubic-bezier(0.12, 0.72, 0.29, 1) 0s;
  box-shadow: rgba(0, 0, 0/ 12, 0.3) 0px 2px 4px;

  .dropdownItem {
    width: 100%;
    height: 2.2em;
    display: flex;
    align-items: center;
    padding: 0 5%;
    input {
      cursor: pointer;
      accent-color: #a94064;
    }
    div {
      @include typography-primary(0.9em, bold, #0f324d);
      padding-inline-start: 5%;
    }
  }
}
