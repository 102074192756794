@import '../../../utils/utils.scss';

.dashproductcard {
  position: relative;
  width: 100%;
  height: 7em;
  border: 1px solid rgba(15, 50, 77, 0.45);
  margin: 1em 0 0 0;
  border-radius: 10px;
  display: flex;
  padding: 1%;
  .holder {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .desktopTitle,
    .mobileTitle,
    .desktop > .price,
    .desktop > .createdAt {
      height: 100%;
      padding: 1% 0;
      .label {
        height: 30%;
        width: 100%;
        @include typography-primary(1em, bold, rgba(0, 0, 0, 0.7));
      }
      .text {
        height: 70%;
        width: 100%;
        @include typography-primary(1em, 500, rgba(15, 50, 77, 1));
      }
    }

    .desktopHead,
    .mobileHead {
      align-items: center;
      justify-content: center;
      height: 100%;
      display: flex;
      img {
        height: 100%;
        width: auto;
        aspect-ratio: 1 / 1;
        object-fit: cover;
        border-radius: 100%;
        border: 1px solid rgba(15, 50, 77, 0.45);
      }
    }
    .desktopHead {
      width: 18%;
    }
    .mobileHead {
      width: 25%;
      padding: 1.5% 0;
    }
    .desktopTitle {
      width: 28%;
    }
    .mobileTitle {
      width: 35%;
      .label {
        height: 20%;
        @include typography-primary(small, bold, rgba(0, 0, 0, 0.7));
      }
      .text::-webkit-scrollbar {
        display: none;
      }
      .text {
        height: 80%;
        width: 100%;
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        @include typography-primary(0.9em, normal, rgba(15, 50, 77, 1));
        align-items: start;
      }
    }
    .desktop {
      height: 100%;
      width: 54%;
      display: flex;
      .price {
        width: 50%;
        .text {
          color: #A94064;
          font-weight: bold;
        }
      }
      .createdAt {
        width: 50%;
      }
    }
    .mobile {
      height: 100%;
      width: 37%;
      padding: 1% 0;
      .price,
      .createdAt {
        height: 50%;
        width: 100%;

        .label {
          height: auto;
          width: 100%;
          @include typography-primary(small, bold, rgba(0, 0, 0, 0.7));
        }
        .text {
          height: 70%;
          width: 100%;
          @include typography-primary(0.9em, 500, rgba(15, 50, 77, 1));
        }
      }
      .price {
        .text {
          color: #A94064;
          font-weight: bold;
        }
      }
    }
  }
}
