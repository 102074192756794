@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@583&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '../utils/utils.scss';
@import 'react-quill/dist/quill.snow.css';
@import 'custom/swipper.scss';

:root {
  --app-height: 100%;
}

html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100dvh;
  background-color: #f6f6f6;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  @media not all and (hover: hover) {
    height: var(--app-height);
  }
  ion-app {
    background-color: #f6f6f6;
  }

  ion-app {
    background-color: #f6f6f6;
  }

  ion-content {
    --background: #f6f6f6;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 0.5vh;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.014);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: #a94064;
    border-radius: 1vh;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #a94064;
  }
}
#pageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  aspect-ratio: 1/1;
}
#filterModal {
  padding: 0.5em 1em;

  ::-webkit-scrollbar {
    width: 0;
  }
  #ion-react-wrapper {
    padding: 0.5em 1em;
    overflow-y: scroll;
    margin: 1em 0;
    .topBar {
      width: 100%;
      height: 10vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      div {
        @include typography-primary(1.2em, bold, #0f324d);
      }
    }
  }
}
#delete-alert .alert-wrapper {
  .alert-message,
  .alert-title,
  .alert-sub-title {
    justify-content: center !important;
    text-align: center !important;
  }
  .alert-title {
    @include typography-primary(1.4em, bold, #a94064);
  }
  .alert-sub-title {
    @include typography-primary(1em, bold, #0f324d);
  }
  .alert-message {
    @include typography-primary(1em, normal, #0f324d);
  }
}
.mapboxgl-canvas {
  position: relative !important;
  left: 0;
  top: 0;
}
ion-card {
  box-shadow: none;
  border: solid 0.1px rgba(192, 188, 188, 1);
}
.sticky_nav {
  position: fixed;
  top: 0;
  width: 100%;
}

ion-header,
ion-toolbar,
ion-button,
ion-card {
  --box-shadow: none !important;
  box-shadow: none !important;
}

.swiper-button-next,
.swiper-button-prev {
  background-color: white;
  width: calc(
    (var(--swiper-navigation-size) * 0.5) + var(--swiper-navigation-size)
  ) !important; 
  height: calc(
    (var(--swiper-navigation-size) * 0.5) + var(--swiper-navigation-size)
  ) !important;
  border-radius: 50%;
}
