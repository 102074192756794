@import '../../../../utils//utils.scss';

.admin_root_holder {
  height: 9dvh;
  width: 100%;
  display: flex;
  align-items: center;
  background: white;
  ion-grid {
    width: 100%;
    height: 100%;
    padding: 2% 5%;
    ion-row {
      width: 100%;
      height: 100%;
      justify-content: space-between;
      ion-col {
        height: 100%;
        padding: 0;
      }
      .mobile_logo {
        height: 6vh;
        aspect-ratio: 1/1;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: auto;
        cursor: pointer;
        ion-icon {
          height: 100%;
          aspect-ratio: 1/1;
          width: auto;
          margin-right: 5px;
        }
        span {
          @include typography-primary(1.2em, 600, var(--ion-color-primary));
          width: fit-content;
        }
        .span2 {
          color: var(--ion-color-secondary);
          font-style: italic;
          padding-inline-start: 2px;
        }
        .span3 {
          margin-left: 7px;
          padding-inline-start: 3px;
          border-left: 2px solid var(--ion-color-primary);
        }
      }
      .menu {
        display: flex;
        height: 100%;
        width: auto;
        aspect-ratio: 1/1;
      }
    }
  }
}

.menu_holder {
  height: 100%;
  width: 100vw;
  overflow: hidden !important;
  --min-width: 100vw;
  --min-height: 100%;
}
