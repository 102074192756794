@import '../../../utils/utils.scss';

.landing_desktop,
.landing_mobile {
  width: 100vw;
  overflow-y: scroll;
  overflow-x: hidden;
  .faqs {
    width: 100%;
    height: auto;
    padding: 2% 5%;
    .label {
      width: 100%;
      height: 10vh;
      padding: 1% 0;
      @include typography-primary(unset, bold, var(--ion-color-secondary));
    }
    .faqsContainer {
      width: 100%;
      height: auto;
      padding: 0 2%;
    }
    .more {
      width: 100%;
      height: 10vh;
      cursor: pointer;
      justify-content: center;
      @include typography-primary(1.2em, bold, var(--ion-color-secondary));

      a{
        @include typography-primary(1em, bold, var(--ion-color-secondary));

      }
    }
  }
  .footer_landing {
    position: relative;
    width: 100%;
    height: auto;
  }
}

.landing_desktop {
  height: 100dvh;
  position: relative;
  .content {
    height: 100%;
    width: 100%;
    display: inline;
    .becomevendor,
    .whyus,
    .contact,
    .faqs {
      width: 100%;
      height: auto;
      padding: 2% 5%;
      overflow:hidden;
      .label {
        width: 100%;
        height: 10vh;
        padding: 1% 0;
        @include typography-primary(2em, bold, var(--ion-color-secondary));
      }
    }
    .showcase {
      width: 100%;
      height: 100%;
      ion-grid {
        width: 100%;
        height: 100%;
        padding: 0;
        .container {
          width: 100%;
          height: 100%;
          padding: 0;
          ion-col {
            height: 100%;
            padding: 0;
          }
          .left {
        
            .nav {
              width: 100%;
              height: 10%;
            }
            .typography {
              width: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              // justify-content: space-evenly;
              padding: 20% 5%;
              .primary,
              .secondary {
                width: 100%;
                @include typography-primary(2.3em, 600, unset);
              }
              .primary {
                height: auto;
                color: var(--ion-color-primary);
                font-size: 2rem;
                margin-bottom: 10px;
              }
              .secondary {
                margin-top: 20px;
                color: var(--ion-color-secondary);
                font-size: 1.5em;
                font-weight: 400;
              }
              .links {
                width: 100%;
                padding: 10% 0;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                ion-button {
                  min-width: 35%;
                  height: 50px;
                  --border-radius: 50px;
                  margin-right: 10px;
                  @include typography-primary(
                    1em,
                    600,
                    var(--ion-color-secondary-contrast)
                  );
                  --box-shadow: none;
                }
              }
            }
          }
          .right {
            padding-bottom: 2%;
            .mainContainer {
              width: 100%;
              height: 100%;
              background: var(--ion-color-primary);
              border-end-start-radius: 100px;
              display: flex;
              flex-direction: column;
              align-items: center;
              .imageHolder {
                width: 40%;
                height: 65%;
                border-end-end-radius: 25vw;
                border-end-start-radius: 25vw;
                background: var(--ion-color-secondary);
                display: flex;
                justify-content: center;
                align-items: flex-start;
                .img {
                  width: 80%;
                  height: 85%;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-top: 2dvh;
                  ion-img {
                    height: 100%;
                    width: auto;
                    aspect-ratio: 1/1;
                  }
                }
              }
              .statHolder {
                width: 100%;
                height: 35%;
                padding: 3% 0 5% 0;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                .statItem {
                  width: 35%;
                  height: 100%;
                  .label,
                  .description,
                  .statistic {
                    @include typography-primary(unset, bold, unset);
                    justify-content: center;
                  }
                  .label {
                    color: var(--ion-color-secondary);
                    width: 100%;
                    height: 10%;
                    font-size: 1.1em;
                  }
                  .icon {
                    width: 100%;
                    height: 35%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 3% 0;

                    ion-icon {
                      height: 100%;
                      aspect-ratio: 1/1;
                      width: auto;
                      // svg {
                      // }
                    }
                  }
                  .statistic {
                    color: var(--ion-color-secondary);
                    width: 100%;
                    height: 20%;
                    font-size: 1.5em;
                  }
                  .description {
                    color: var(--ion-color-light);
                    width: 100%;
                    height: 35%;
                    font-size: small;
                    text-align: center;
                  }
                }
              }
            }
          }
        }
      }
    }
    .becomevendor {
      .steps {
        width: 100%;
        @include typography-primary(unset, bold, unset);
      }
  
        .sub {
          font-size: 1.2em;
          color: var(--ion-color-primary);
          align-items: start;
        }
        .video {
          width: 57%;
          height: 100%;
          overflow:hidden;
          border-radius:15px;
          video {
            margin-top: 5%;
            width: 100%;
            height: 100%;
            object-position: center;
            object-fit: cover;
          }
        
      }

      .steps {
        width: 100%;
        height: auto;
        align-items: start;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-flow: wrap;
        padding: 0 5%;
        .steps_container {
          width: 40%;
          height: 25vh;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .head {
            position: relative;
            width: 100%;
            height: 30%;
            display: flex;
            align-items: center;
            padding: 0.5% 0;
            cursor: pointer;
            .icon {
              height: 100%;
              width: auto;
              aspect-ratio: 1/1;
              background: var(--ion-color-secondary);
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2% 0;

              border-radius: 8px;
              .icon_data {
                color: var(--ion-color-secondary-contrast);
                height: 100%;
                width: auto;
                aspect-ratio: 1/1;
              }
            }
            .label {
              padding-inline-start: 5%;
              font-size: 1.3em;
            }
          }
          .body {
            width: 100%;
            height: 66%;
            @include typography-primary(1.2em, bold, var(--ion-color-primary));
            align-items: start;
          }
        }
      }
    }
    .whyus {
      .list > .listitem {
        width: 100%;
        @include typography-primary(unset, bold, var(--ion-color-primary));
      }
      .sub {
        font-size: 1.2rem;
        color: var(--ion-color-primary);
        align-items: start;
      }
      .list {
        width: 100%;
        height: auto;
        align-items: start;
        justify-content: space-around;
        flex-wrap: wrap;
        flex-flow: wrap;
        list-style-type: decimal;
        list-style: decimal;
        margin-top: 20px;

        .listitem {
          width: 100%;
          margin: 20px 0;
          font-size: 1.2em;
          font-weight: 600;
          list-style-type: decimal;
          list-style: decimal;
        }
      }
    }
    .contact {
      .contactHolder {
        width: 70%;
        // height: 70dvh;
      }
    }
  }
}
.landing_nav {
  height: 10dvh;
  width: 100vw;
  top: 0;
  z-index: 10;
}
.landing_mobile {
  height: 90dvh;
  position: relative;
  .content {
    position: relative;
    height: fit-content;
    width: 100%;
    overflow: hidden;
    .showcase {
      width: 100%;
      height: 89dvh;
      background: var(--ion-color-primary);
      border-end-end-radius: 50vw;
      border-end-start-radius: 50vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      .imageHolder {
        width: 60%;
        height: 50%;
        border-end-end-radius: 50vw;
        border-end-start-radius: 50vw;
        background: var(--ion-color-secondary);
        display: flex;
        justify-content: center;
        align-items: flex-start;

        .img {
          width: 80%;
          height: 85%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 2dvh;
          ion-img {
            height: 100%;
            width: auto;
            aspect-ratio: 1/1;
          }
        }
      }
      .statHolder {
        width: 100%;
        height: 50%;
        padding: 0 20% 10% 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        .statItem {
          width: 100%;
          height: 40%;
          .label,
          .description,
          .statistic {
            @include typography-primary(unset, bold, unset);
            justify-content: center;
          }
          .label {
            color: var(--ion-color-secondary);
            width: 100%;
            height: 10%;
            font-size: 1em;
          }
          .icon {
            width: 100%;
            height: 35%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 2% 0;

            ion-icon {
              height: 100%;
              aspect-ratio: 1/1;
              width: auto;
            }
          }
          .statistic {
            color: var(--ion-color-secondary);
            width: 100%;
            height: 20%;
            font-size: 1.2em;
          }
          .description {
            color: var(--ion-color-light);
            width: 100%;
            height: 35%;
            font-size: 0.8em;
            text-align: center;
          }
        }
      }
    }
    .typography {      
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 10% 10%;
      .primary,
      .secondary {
        width: 100%;
        @include typography-primary(2.3em, 600, unset);
      }
      .primary {
        height: auto;
        color: var(--ion-color-primary);
        font-size: 1.5rem;
        text-align: center;
      }
      .secondary {
        color: var(--ion-color-secondary);
        font-size: 1.2rem;
        text-align: center;
        font-weight: 400;
        margin-top: 20px;
      }
      .links {
        width: 100%;
        padding: 4% 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        ion-button {
          width: 80%;
          height: 50px;
          --border-radius: 50px;
          margin-right: 10px;
          @include typography-primary(
            1em,
            600,
            var(--ion-color-secondary-contrast)
          );
          --box-shadow: none;
        }
      }
    }
    .becomevendor {
      width: 100%;
      height: auto;
      padding: 2% 5%;
      .label,
      .steps {
        width: 100%;
        @include typography-primary(unset, bold, unset);
      }
      .label {
        height: 10vh;
        font-size: 1.6em;
        color: var(--ion-color-secondary);
        padding: 2% 0;
      }
      .sub {
        font-size: 1.1rem;
        color: var(--ion-color-primary);
        align-items: start;
      }
      .video {
        margin-top: 10%;

        width: 100%;
        padding: 0;
        height:35vh;
        overflow: hidden;
        border-radius:10px;
        video {
          width: 100%;
          height: 100%;
        }
      }
      .steps {
        width: 100%;
        height: auto;
        align-items: start;
        flex-direction: column;
        justify-content: space-between;
        padding: 3% 5%;
        .steps_container {
          width: 100%;
          height: 30vh;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;

          .head {
            position: relative;
            width: 100%;
            height: 20%;
            display: flex;
            align-items: center;
            padding: 0.5% 0;
            cursor: pointer;
            .icon {
              height: 100%;
              width: auto;
              aspect-ratio: 1/1;
              background: var(--ion-color-secondary);
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 2% 0;

              border-radius: 8px;
              .icon_data {
                color: var(--ion-color-secondary-contrast);
                height: 100%;
                width: auto;
                aspect-ratio: 1/1;
              }
            }
            .label {
              padding-inline-start: 5%;
              font-size: 1.3em;
            }
          }
          .body {
            width: 100%;
            height: 76%;
            padding: 3% 0;
            @include typography-primary(1.2em, bold, var(--ion-color-primary));
            align-items: start;
          }
        }
      }
    }
    .whyus {
      width: 100%;
      height: auto;
      margin-top: 10%;
      padding: 0 5%;
      .label,
      .list > .listitem {
        width: 100%;
        @include typography-primary(unset, bold, var(--ion-color-primary));
      }
      .label {
        font-size: 1.6rem;
        color: var(--ion-color-secondary);
        margin-bottom: 20px;;
      }
      .sub {
        font-size: 1.1rem;
        font-weight: 400;
        color: var(--ion-color-primary);
        align-items: start;
      }
      .list {
        height: auto;
        margin-top: 20px;
        .listitem {
          font-weight: 600;
          margin: 10px 0;
          list-style-type: decimal;
          list-style: decimal;
          font-size: 1.2rem;
        }
      }
    }
    .contact {
      width: 100%;
      height: auto;
      padding: 2% 5%;
      .label {
        width: 100%;
        height: 8vh;
        padding: 3% 0;
        @include typography-primary(1.6em, bold, var(--ion-color-secondary));
      }
      .contactHolder {
        width: 100%;
        height: auto;
      }
      .sub {
        text-align: left !important;
      }
    }
    .faqs {
      .label {
        font-size: 1.3em;
      }
    }
  }
}
