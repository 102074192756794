@import '../../utils/utils.scss';

.itemListCard {
  position: relative;
  width: 100%;
  height: auto;
  margin: 0;
  border-radius: 15px;
  border: 1px solid #0f324d6b;
  .itemContent {
    position: relative;
    width: 100%;
    height: fit-content;
    padding: 0.7em;
    .itemGrid {
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      ion-row {
        width: 100%;
        ion-col {
          height: 100%;
        }
      }
      .head {
        height: 2.5em;
        background: white;
        .title {
          @include typography-primary(
            $size: 1em,
            $weight: bold,
            $color: #0f324d
          );
        }
        .icn {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          ion-button {
            height: 100%;
          }
        }
      }
      .actions {
        height: auto;
        width: 100%;
        .sortMenu {
          height: 10em;
          border-bottom: 1px solid #0f324d5a;
          width: 100%;
          margin: 1%;
          padding: 1% 0;
          display: flex;
          .sorting,
          .filter {
            display: flex;
            flex-direction: column;
            margin-right: 10px;
            .label {
              width: 100%;
              height: auto;
              @include typography-primary(small, bold, #0f324d);
            }
            .options {
              height: 100%;
              overflow-y: scroll;
              overflow-x: hidden;
              .item {
                width: 100%;
                height: 17%;
              }
            }
          }
          .sorting {
            border-right: 1px solid #0f324d76;
          }
          .filter {
            .options {
              padding:0 5%;
              .accordGroup {
                position:relative;
                height: 100%;
                width: 100%;
                margin:0;
                .accord {
                  box-shadow:none;
                  width:100%;
                  min-height:20%;
                  display: flex;
                  flex-direction:column;
                  justify-content: center;
                  border-radius: 0;
                  cursor:pointer;

                  .accordName:hover{
                    background:#0f324d0e;
                  }
                  .accordName {
                    width:100%;
                    @include typography-primary(1em , bold, #0f324d);
                  }
                  .accordContent {
                    width:100%;
                    height:auto;
                    padding: 0 5%;
                    .item:hover{
                       background:#0f324d0e;
                    }
                    .item{
                      position:relative;
                      width:100%;
                      height:auto;
                      padding:5% 1%;
                      @include typography-primary(1em , normal, #091f30);
                      align-items: center;
                      border-bottom:1px solid #0f324d83;
                      
                    }
                  }

                }
              }
            }
          }
          .active {
            height: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: start;
            align-content: flex-start;
            overflow-x: hidden;
            overflow-y: scroll;
          }
        }
        .sort {
          padding: 0.5% 1%;
          overflow: hidden;
          .sortHolder {
            height: 2.1em;
            width: fit-content;
            position: relative;
            display: flex;
            align-items: center;
            padding: 0 1%;
            background: white;
            border: 1px solid #0f324d;
            border-radius: 20px;
            cursor: pointer;
            overflow: hidden;
            .label {
              height: 100%;
              width: auto;
              @include typography-primary(0.8em, bold, #0f324d);
            }
            .btn {
              height: 80%;
              width: 15px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-left: 4px;
            }
          }
        }
        .display {
          height: 2.3em;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.2em;
        }
      }
      ._list {
        height: 85%;
        .list_container {
          position: relative;
          width: 100%;
          height: 100%;
          padding: 0 0 1em 0;
          display: flex;
          flex-direction: column;
          .itemGroup {
            width: 100%;
            height: auto;
            .groupLabel {
              width: 100%;
              height: auto;
              padding: 1% 2%;
              display: flex;
              align-items: center;
              justify-content: space-between;
              @include typography-primary(1em, bold, #0f324d);
              border-bottom: 1px solid #0f324d6b;
              padding-inline-start: 2%;
            }
            .items {
              // width: 100%;
              // height: auto;
              // display: flex;
              // flex-direction: row;
              // align-content: flex-start;
              // flex-wrap: wrap;
              // align-content: flex-start;
            }
          }
        }
      }
    }
  }
}

.option {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 3px;
  padding-inline-start: 2%;
  overflow: hidden;
  //border-bottom: 1px solid #0f324d6b;
  cursor: pointer;
  @include typography-primary(1em, bold, #0f324d);
}
.option:hover {
  background: rgb(244, 244, 244);
}
