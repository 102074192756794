.container{
    width: 100%;
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    .sec1{
        width: 100%;
        margin-top: -10px;
        p{
            font-size: 17px;
            color: #0A2B45;
            font-weight: 600;
        }
        .search_container{
            position: relative;
            input{
                width: 100%;
                border-radius: 5px;
                margin-top: -7px;
                padding-left:20px ;
                background-color: #e6e5e5;
                border: none
            }
            .search_icon{
                position: absolute;
                top: 2px;
                left: 0.2em;
                color: gray ;
            }
        }
    }
    .sec2{
        display: flex;
         justify-content: space-between;
        width: 100%;
        font-size:12px ;
        color: #0A2B45;
        font-weight: 600;
        margin-top: 10px;
        .group{
            width: 150px;
            border-radius: 5px;
            height: 22px;
            background-color: #e6e5e5;
            border: none;
        }
        .select{
            position: relative;
            .filter{
                width: 100px;
                border-radius: 5px;
                height: 22px;
                background-color: #e6e5e5;
                padding-left:15px ;
                border: none;
            }
            .select_icon{
                position: absolute;
                left: 0.3em;
                color: #0A2B45;
                top: 4px;
            }
        }
      
    }
}

@media screen and (min-width: 768px) {
    .container{
    .sec1{
        display: flex;
        justify-content: space-between;
        align-items: center;
        p{
            font-size: 26px;
        }
    .search_container{
        input{
            height: 35px;
            padding-left: 27px;
            font-size: 18px;
        }
        .search_icon{
            font-size: 20px;
            top: 1px;
        }
    }
}
     .sec2{
        font-size:17px ;
        .select{
            .filter{
                width: 120px;
                height: 30px;
                padding-left:20px ;
            }
            .select_icon{
                font-size: 16px;
                top: 7px;
            }
       }
        .group{
            width: 170px;
            height: 30px;
        }
     }
}
}