
//searchField
.searchInput {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .input {
    position: relative;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    border-bottom: 1px solid #0f324d;
    font-size: 1.2em;
    padding: 0.2em 0;
    height: 100%;
  }

  .btn {
    position: relative;
    --border-radius: 50px;
  }
}
