// Filters Component
.filtersGrid {
  width: 100%;
  height: 100%;
  padding: 0;
  overflow: hidden;
  ion-row {
    width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    ion-col {
      height: 100%;
      ion-label {
        font-family: 'Roboto', sans-serif;
        font-size: 0.8em;
        margin: 5px 0;
        font-weight: bold;
        color: rgba(0, 0, 0, 0.7);
        padding-inline-start: 5px;
      }
    }
  }
  .categories,
  .locations {
    max-height: 60vh;
    overflow-y: scroll;
    margin: 5px 0;
    ion-col {
      .categoriesList,
      .locationList {
        position: relative;
        width: 70%;
        min-height: 25vh;
        padding: 0;
        list-style: none;
        margin: 5px auto;
        li {
          width: 100%;
          height: 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          label {
            color: rgba(0, 0, 0, 0.9);
            font-family: 'Roboto', sans-serif;
            font-size: 1em;
          }
          input {
            cursor: pointer;
            accent-color: #A94064;
          }
        }
      }
    }
  }

  .price {
    position: relative;
    height: 100%;
  }
}