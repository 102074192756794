@import "../../../utils/utils.scss";

.storesContainer {
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  padding: 0 8px;
  ion-row {
    width: 100%;
    ion-col {
      height: 100%;
    }
  }
    .head {
      width: 100%;
      height: 5.5vh;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 0 2%;
      .title {
        width: auto;
        display: flex;
        align-items: center;
        @include typography-primary(1.2em, bold, #0f324d);
      }
      .filterBar {
        position: relative;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .content {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 2% 1%;
    }
}
