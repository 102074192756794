.AppCarrier {
  height: 100%;
  width: 100vw;
  display: flex;
  flex-direction: column;
  .nav {
    width: 100%;
    height: 10vh;
    border-bottom: 1px solid rgba(15, 50, 77, 0.5);
    position: fixed;
    z-index: 9999999;
    background: white;
  }
  .bodyContent {
    margin-top:10dvh;
    min-height: 65vh;
    padding: 0;
    .breadCrumbs {
      position: relative;
      width: 100%;
      height: 3vh;
      ion-breadcrumbs {
        position: relative;
        height: 100%;
        ion-breadcrumb {
          height: 100%;
          font-size: 0.8em;
        }
      }
    }
    .children {
      width: 100%;
      // min-height: 87vh;
    }
  }
  .footer {
    width: 100%;
  }
}
.popover-backdrop {
  display: none;
}
@media only screen and (max-width: 600px) {
  ::-webkit-scrollbar {
    width: 0 !important;
  }
}
