.root_container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .root_content {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
      align-items: center;
    .root_grid {
      width: 100%;
      height: 100%;
      padding: 0;
      margin: 0;
      .root_row {
        height: 100%;
        width: 100%;
        padding: 1%;
        margin: 0;
        justify-content: space-between;
        ion-col {
          padding: 0;
          margin: 0;
        }
        .root_children {
          border-radius: 15px;
          overflow: hidden;
          background: rgba(10, 43, 69, 0.1);
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

.root_content_mobile {
  width: 100%;
  border-start-start-radius: 15px;
  overflow: hidden;
  background: #e6e5e5;
}
