@import '../../../utils/utils.scss';

.container {
  min-height: 40dvh;
  height: auto;
  padding: 0;
  position: relative;
  overflow: hidden !important;
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background: white;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actions {
    width: 100%;
    height: 8%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1% 3%;
  }
  .content {
    height: 92%;
    padding: 20px;
    .head {
      width: 100%;
      .title {
        width: 100%;
        height: 50%;
        @include typography-primary(2em, bold, #a94064);
        justify-content: center;
        margin: 0.5em 0;
      }
      .navigator {
        width: 100%;
        height: 50%;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .background {
          height: 6px;
          background: rgb(189, 198, 205);
        }
        .progress {
          position: absolute;
          z-index: 1;
          background: #a94064;
          height: 6px;
        }
        .breakpoint {
          aspect-ratio: 1/1;
          width: 20px;
          background: #a94064;
          border-radius: 50%;
          position: absolute;
          z-index: 2;
          cursor: pointer;
        }
        .breakpoint:hover {
          background: rgba(169, 64, 100, 0.87);
        }
      }
    }
    .form {
      width: 100%;
    }
    .controls {
      width: 100%;
      height: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      ion-button {
        min-width: 150px;
        margin: 0 5px;
        font-weight: bold;
      }
    }
  }
}

.desktop {
  .container {
    background: red;
  }
}

// forms
.info,
.contacts,
.location {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .helperText {
    width: 100%;
    height: 10%;
    @include typography-primary(0.8em, 200, rgb(97, 95, 95));
    // padding-inline-start: 2%;
    line-height: 1.2;
    margin: 5px 0;
  }
}
.info {
  margin: 5% 0;
  .typography {
    width: 100%;
    height: 100%;
    ion-grid {
      width: 100%;
      height: 100%;
      padding: 0;
      .label {
        @include typography-primary(1em, bold, rgb(15, 50, 77));
        height: 35%;
      }
      .input {
        height: 65%;
      }
      .head {
        margin: 2% 0;
        width: 100%;
        ion-col {
          justify-content: space-between;
        }
        .name {
        }
        .type {
        }
      }
      .type{
        margin: 2% 0;
        width: 100%;
      }
      .description {
        margin: 2% 0;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        ion-col {
          height: 100%;
          width: 100%;
        }
        .label {
          height: 8%;
        }
        .container {
          width: 100%;
          height: 23dvh;
          padding: 0 1%;
        }
      }
    }
  }
}
.contactContainer {
  width: 100%;
  height: 100%;
  margin: 5% 0;
  .label {
    @include typography-primary(1em, bold, rgb(15, 50, 77));
    width: 100%;
    height: 1.3em;
    position: relative;
    justify-content: start;
    span:hover {
      color: rgb(235, 71, 79);
      border: 1px solid rgb(235, 71, 79);
    }
    span {
      position: absolute;
      height: 80%;
      top: 0;
      left: 14%;
      aspect-ratio: 1/1;
      @include typography-primary(small, bold, rgb(15, 50, 77));
      cursor: pointer;
      border-radius: 50%;
      border: 1px solid rgb(15, 50, 77);
      justify-content: center;
    }
  }
  .email {
    width: 100%;
    margin: 1% 0;
  }
  .contacts {
    width: 100%;
    height: auto;
    margin: 1% 0;
    .container {
      width: 100%;
      height: auto;
      .input {
        width: 100%;
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .field {
          height: 100%;
          width: 75%;
        }
        ion-button {
          width: 20%;
          height: 100%;
        }
      }
      .list {
        width: 100%;
        height: auto;
        margin-top: 1%;
        padding: 20px;
        background: #f5f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 15px;
        overflow-y: scroll;
        .item {
          width: 100%;
          height: 30px;
          @include typography-primary(1em, bold, rgb(15, 50, 77));
          margin: 5px 0;
          justify-content: space-between;
          ion-button::part(native) {
            width: 100%;
            height: 100%;
            padding: 0;
          }
          ion-button {
            height: 100%;
            width: auto;
            aspect-ratio: 1/1;
            ion-icon::part(native) {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}

.location {
  width: 100%;
  height: 100%;
  display: flex;
  margin: 5% 0;
  flex-direction: column;
  .images {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 1% 0;
    .cont {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      .label {
        @include typography-primary(1em, bold, rgb(15, 50, 77));
        width: 100%;
        position: relative;
        justify-content: center;
      }
      .discard {
        position: absolute;
        width: auto;
        height: 25px !important;
        aspect-ratio: 1/1;
        z-index: 2;
        ion-button::part(native) {
          width: 100%;
          height: 100%;
          padding: 0;
        }
        ion-button {
          aspect-ratio: 1/1;
          width: 100%;
          height: auto;
          margin: 0;
          --border-radius: 50%;
          --font-weight: bold;
        }
      }
      .display {
        width: 100%;
        height: 100%;
        overflow: hidden;
        img {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      .profile {
        height: 100%;
        width: auto;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .imageHolder {
          aspect-ratio: 1/1;
          background: rgb(15, 50, 77);
          border-radius: 50%;
          height: 90%;
          width: auto;
          overflow: hidden;
          display: flex;
          justify-content: center;
          .input {
            // height: 100%;
            position: relative;
            width: 100%;
            @include typography-primary(1em, bold, whitesmoke);
            flex-direction: row;
            justify-content: center;
            cursor: pointer;
            input {
              display: none;
            }
          }
        }
      }
      .banner {
        height: 100%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .imageHolder {
          width: 100%;
          height: 88%;
          position: relative;
          border-radius: 15px;
          background: #d9d9d9;
          overflow: hidden;
          .overlay {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            right: 0;
            background: rgba(0, 0, 0, 0.05);
            position: absolute;
            overflow: hidden;
            z-index: 1;
           
            .input {
              width: 100%;
              height: 100%;
              position: relative;
              display:flex;
              justify-content:center;
              align-items:center;
              ion-icon{
                
              }
              cursor: pointer;
              input {
                display: none;
              }
            }
          }
          .discard {
            right: 3%;
            top: 3%;
          }
        }
      }

      .message {
        position: absolute;
        left: 0;
        bottom: 0;
        height: 25%;
        width: 40%;
        @include typography-primary(1em, bold, rgb(235, 71, 79));
        justify-content: space-between;
      }
    }
  }
  .holder {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 1% 0;
    .label {
      @include typography-primary(1em, bold, rgb(15, 50, 77));
      width: 100%;
      margin: 5px 0;
      position: relative;
      justify-content: start;
    }
    .helperText {
      height: fit-content;
      margin: 5px 0;
    }
    .container {
      width: 100%;
      height: auto;
      margin: 2% 0;
      .input {
        width: 100%;
        height: 2.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .field {
          height: 100%;
          width: 75%;
        }
        ion-button {
          width: 20%;
          height: 100%;
        }
      }
      .list {
        width: 100%;
        height: fit-content;
        margin-top: 2%;
        padding: 5px 15px;
        background: #f5f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        border-radius: 15px;
        overflow-y: scroll;
        .item {
          width: 100%;
          height: 2rem;
          @include typography-primary(1em, bold, rgb(15, 50, 77));
          margin: 5px 0;
          justify-content: space-between;
          ion-button::part(native) {
            width: 100%;
            height: 100%;
            padding: 0;
          }
          ion-button {
            height: 100%;
            width: auto;
            aspect-ratio: 1/1;
            ion-icon::part(native) {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .error {
    height: 30%;
    width: 100%;
    padding: 0 2%;
    .label {
      @include typography-primary(1.1em, bold, rgb(235, 71, 79));
    }
    .cont {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .msg {
        width: 80%;
        @include typography-primary(1em, bold, rgb(235, 71, 79));
      }
      .btn {
        height: 100%;
        width: 18%;
        padding: 2% 0;
        overflow: hidden;
        display: flex;
        align-items: center;
        ion-button::part(native) {
          width: 100%;
          height: 100%;
          padding: 0;
        }
        ion-button {
          width: 100% !important;
          height: 100%;
          min-width: unset;
        }
      }
    }
  }
}
