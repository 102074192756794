.content-wrapper {
	margin-top: -3vh;
	.greeting {
		background: #10324d;
		color: white;
		padding: 10px 20px;
		min-height: 80px;
		display: flex;
		flex-direction: column;
		justify-content: center;

		h3 {
			font-weight: bold;
		}
		h6 {
			font-size: 0.9rem;
		}
	}

	ion-row {
		height: 100%;
		ion-col {
			height: inherit;
		}

		.menu {
			border-radius: 5px;
			margin: 10px 10px 10px 20px;
			background: white;
			.section-head {
				--background: #f1f1f5;
				--background: #d4d4d6;
				--color: #10324d;
			}
			ion-item {
				--detail-icon-color: #10324d;
				--detail-icon-opacity: 0.7;
				ion-icon {
					color: #10324d;
				}
			}
			ion-item::part(native) {
				min-height: 60px;
			}
			.delete-item {
				color: #a94064;
				--detail-icon-color: #a94064;

				ion-icon {
					color: #a94064;
				}
			}
		}

		.content {
			background: white;
			margin: 10px 20px 10px 10px;
			height: calc(100% - 20px);
			border-radius: 5px;
			padding: 15px 20px 20px 20px;
		}

		.temp-menu-col {
			border-radius: 10px !important;
			background: white;
			padding-bottom: 50px;
		}
	}
}
