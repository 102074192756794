.page {
  padding: 30px 0 0 0;
  ion-row {
    display: flex;
    justify-content: center;
    ion-col {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        text-align: center;
        font-size: 1rem;
        color: #0A2B45;
      }
      .welcome-text {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 0;
      }

      ion-button {
        min-height: 60px;
        width: 80%;
        --border-radius: 30px;
        text-transform: none;
        font-size: 1rem;
        font-weight: bold;
      }
      .vendor-btn {
        --background: #A94064;
        margin-top: 20px;
      }
      .shopper-btn {
        margin-top: 15px;
      }
    }
  }
  .vendor-btn-row {
    margin-top: 30px;
  }
  .shopper-btn-row {
    margin: 15px 0 30px 0;
  }
}
