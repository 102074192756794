.mainContainer {
  position: relative;
  height: 25vh;
    .form {
      position: relative;
      width: 90%;
      height: 80%;
      margin: 5px auto;
      .priceRange {
        width: 100%;
        height: 50%;
        border: none;
        .range {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: center;
          .labels {
            width: 100%;
            height: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            span {
              font-family: system-ui, -apple-system, BlinkMacSystemFont,
                'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
                'Helvetica Neue', sans-serif;
              font-size: 0.9em;
              color: rgba(0, 0, 0, 0.7);
            }
          }

          .slider {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
            .overlay {
              position: absolute;
              background: #A94064;
              height: 3px;

              z-index: 2;
            }
            #fromSlider {
              height: 0;
              z-index: 1;
            }
            input[type='range']::-webkit-slider-thumb {
              -webkit-appearance: none;
              pointer-events: all;
              width: 15px;
              height: 15px;
              background-color: #A94064;
              border-radius: 50%;
              box-shadow: none;
              cursor: pointer;
            }

            input[type='range']::-moz-range-thumb {
              -webkit-appearance: none;
              pointer-events: all;
              width: 15px;
              height: 15px;
              background-color: #A94064;
              border-radius: 50%;
              box-shadow: none;
              cursor: pointer;
            }

            input[type='range']::-webkit-slider-thumb:hover {
              background: #A94064;
            }

            input[type='range']::-webkit-slider-thumb:active {
              z-index: 10;
              box-shadow: inset 0 0 3px #A94064, 0 0 9px #A94064;
              -webkit-box-shadow: inset 0 0 3px #A94064, 0 0 9px #A94064;
            }
            input[type='range'] {
              -webkit-appearance: none;
              appearance: none;
              height: 3px;
              width: 100%;
              position: absolute;
              background-color: rgb(212, 212, 212);
              pointer-events: none;
            }
          }
        }
      }
      .priceInput {
        border: none;
        width: 100%;
        height: 50%;
      }
    }
  }

