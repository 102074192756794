.mobile {
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  ion-row {
    width: 100%;
    ion-col {
      height: 100%;
      padding: 0;
    }
  }
  .topBar {
    height: auto;
    flex: 0 1 auto;
  }
  .container {
    flex: 1 1 auto;
    overflow: hidden;
  }
}
