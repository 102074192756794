@import '../../../../utils/utils.scss';

.Container {
  width: 100%;
  height: 100%;
  .holder {
    width: 100%;
    height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.4);
    ion-grid {
      width: 100%;
      height: 100%;
      padding: 0;
      ion-row {
        height:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .logo {
          height:100%;
          padding-inline-start: 15px;
          display:flex;
          flex-direction: row;
          align-items: center;
          justify-content: start;
          .holder {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 3.5% 0;
            width: auto;
            border:none;
            cursor: pointer;
            ion-icon {
              height: auto;
              aspect-ratio: 1/1;
              
              margin-right: 5px;
            }
            span {
              @include typography-primary(1.3em, 600, white);
              width: fit-content;
            }
            .span2 {
              color: var(--ion-color-secondary);
              font-style: italic;
            }
          }
        }
        .storeMenu {
          height: 100%;
          margin-right: 5%;
          .chip {
            height: 100%;
          }
        }
      }
    }
  }
}
