@import '../../utils/utils.scss';

.main {
  position: relative;
  width: 100%;
  --background: #fff;
  --color: #0f324d;
  font-weight: bold;
  border-radius: 100px;
  padding: 2%;
  margin: 0;
  border: 2px solid var(--ion-color-secondary);
  .store {
    position: relative;
    background: #a94064;
    height: 100%;
    width: 23%;
    border-radius: 100%;
    display: flex;
    margin-right: 5px;
    align-items: center;
    * {
      margin: 0 auto;
      height: 50%;
      width: 50%;
    }
  }
  ion-label {
    height: auto;
    width: 55%;
    padding: 0 1%;
    display: flex;
    align-items: center;
    font-size: 1em;
    white-space: nowrap;
    @include line-clamp($font-size: 1em, $line-height: 1.5, $lines-to-show: 1);
  }
  ion-icon {
    height: 100%;
    width: 15%;
    margin: 0;
  }
}

.menu {
  border-radius:20px;
  .container {
    padding: 0 5%;
    margin: 0;
    ion-card-content {
      padding: 0;
      div {
        .top {
          width: 100%;
          margin: 1%;
        }
        ion-item-group {
          width: 90%;
          margin: 2% auto;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main {
    padding: 3%;
    margin: 0;
    display: flex;
    justify-content: space-evenly;
    .store {
      position: relative;
      background: #a94064;
      border-radius: 20px;
      height: 100%;
      width: 65%;
    }
    ion-label {
      display: none !important;
    }
    ion-icon {
      height: 100%;
      width: 15%;
      margin: 0;
    }
  }
}
