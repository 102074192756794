@import '../../../utils/utils.scss';

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .loading {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .head {
    width: 100%;
    height: 10vh;
    @include typography-primary(1.3em, bold, rgb(15, 50, 77));
    justify-content: center;
  }
  .holder {
    width: 100%;
    height: auto;
    .typography {
      height: auto;
      display: flex;
      flex-direction: column;
      padding: 0 2%;
      .paragraph {
        width: 100%;
        height: auto;
        h3 {
          width: 100%;
          height: 3vh;
          @include typography-primary(1.1em, bold, rgb(15, 50, 77));
        }
        p {
          padding: 0 2%;
          width: 100%;
          @include typography-primary(1em, 500, rgb(15, 50, 77));
        }
      }
      .one {
        p {
          height: 10vh;
        }
      }
      .two {
        ol {
          li {
            @include typography-primary(1em, 500, rgb(15, 50, 77));
            display: list-item;
            width: 100%;
            height: auto;
            list-style: decimal;
            list-style-position: inside;
            margin: 1px 0;
          }
        }
      }
      .three {
        p {
          height: 20vh;
          align-items: flex-start;
        }
      }
    }
    .actions {
      height: auto;
      .fileHolder {
        height: 30vh;
        width: 100%;
        background: rgba(15, 50, 77, 0.02);
        border-radius: 15px;
        .input {
          width: 100%;
          height: 100%;
          border: 5px dashed rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 10% 0;

          p {
            width: 100%;
            height: 5vh;
            margin: 0;
            @include typography-primary(1em, 500, rgb(15, 50, 77));
            justify-content: center;
          }
        }
        .file {
          width: 100%;
          height: 50%;
          display: flex;
          align-items: center;
          padding: 2%;
          border: 1px solid #A94064;
          border-radius: 10px;
          ion-icon {
            width: 20%;
          }
          p {
            width: 60%;
          }
          .btn {
            display: flex;
            align-items: center;
            width: 20%;
            height: 100%;
            justify-content: center;
            padding: 5%;
            div:hover {
              background: rgba(235, 71, 79, 0.1);
            }
            div {
              position: relative;
              aspect-ratio: 1/1;
              width: 100%;
              height: auto;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5px;
              padding: 1%;
              cursor: pointer;
              ion-icon {
                width: 100%;
                height: 100%;
              }
            }
          }
        }
        .errorMessage {
          width: 100%;
          height: 100%;
          @include typography-primary(1.1em, bold, #A94064);
          justify-content: center;
          flex-direction: column;
        }
        .successMessage {
          width: 100%;
          height: 100%;
          @include typography-primary(1.1em, bold, #2dd36f);
          justify-content: center;
          flex-direction: column;
        }
      }
      .sampleHolder {
        width: 100%;
        height: 20vh;
        h3 {
          width: 100%;
          @include typography-primary(1.1em, bold, rgb(15, 50, 77));
        }
        .sample:hover {
          background: rgba(15, 50, 77, 0.03);
        }
        .sample {
          width: 100%;
          height: 15vh;
          position: relative;
          display: flex;
          align-items: center;
          border: 2px solid rgba(15, 50, 77, 0.7);
          border-radius: 10px;
          padding: 3% 5%;
          justify-content: space-between;
          cursor: pointer;
          p {
            width: 70%;
            height: 100%;
            @include typography-primary(1.2em, 500, rgb(15, 50, 77));
          }
          ion-icon {
            width: 10%;
            height: auto;
            aspect-ratio: 1/1;
          }
        }
      }
      .submit {
        width: 100%;
        height: 20vh;
        padding: 10% 0;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        ion-button {
          width: auto;
        }
        ._btn{
          width:100%;
          height:20vh;
          display: flex;
          padding: 10% 0;
          align-items: center;
          justify-content: flex-end;
          ion-button{
            height:auto;
          }
        }
      }
    }
  }
}
