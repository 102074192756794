@import '../../../../../utils/utils.scss';

.Container {
  width: 100%;
  height: inherit;
  background: #0a2b45;
  .holder {
    height: 100%;
    .content {
      position: relative;
      height: 79%;
      margin-top: 1%;
      justify-content: space-evenly;
      .info {
        padding: 2% 10px;
        .logo {
          height: 1.6em;
          display: flex;
          align-items: center;
          width: 100%;
          ion-icon {
            height: 100%;
            aspect-ratio: 1/1;
            width: auto;
            margin: 0 2%;
          }
          span {
            @include typography-primary(1em, bold, white);
            width: fit-content;
          }
          .span2 {
            color: var(--ion-color-secondary);
            font-style: italic;
          }
        }
        div {
          color: #fff;

          font-size: 1.6rem;
          font-weight: 600;
          margin: 3% 0;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
        p,
        h2,
        h3 {
          font-family: 'Roboto', sans-serif;
          margin: 10px 5px;
          color: #fff;
        }
        h2 {
          color: #fff;
          font-weight: 600;
          margin: 0 0 8px 0;
          font-size: 1rem;
        }
        p {
          font-size: 0.9rem;
          color: #c7c7c7;
        }
        h3 {
          font-size: 1.3rem;
          font-weight: bold;
        }
        .icons {
          margin: 5% auto;
          .head {
            font-weight: bold;
            font-size: 1.1rem;
          }
          .list {
            display: flex;
            flex-direction: row;
            background: transparent;
            margin: 0;
            padding: 0;
            .btn {
              --background: transparent;
              --padding-end: 5px;
              --padding-start: 5px;
            }
          }
        }
      }
      .about,
      .join {
        padding: 2% 10px;
        h2 {
          color: #fff;
          font-weight: 600;
          margin: 0 0 8px 0;
          font-size: 1rem;
        }
        .aboutHolder,
        .joinHolder {
          ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            justify-content: flex-end;
            .aboutItem,
            .joinItem {
              color: #d6d6d6;
              cursor: pointer;
              display: flex;
              font-family: 'Roboto', sans-serif;
              font-weight: normal;
              margin-bottom: 6px;
              font-size: 0.9rem;
              align-items: center;
            }
          }
        }
      }
      .newsletter {
        border-left: 1px solid #ffffff25;
        h2,
        p {
          color: white;
          font-family: 'Roboto', sans-serif;
          margin: 3% 20px;
        }
        h2 {
          font-weight: bold;
          font-size: 1.2rem;
        }
        p {
          font-size: 1rem;
          color: #c7c7c7;
        }
        .input {
          position: relative;
          top: 5%;
          --background: transparent;
          margin: 10px 0;
          padding: 0;
          .field {
            --background: white;
            --padding-left: 2px;
            border-radius: 5px;
            margin-right: 10px;
            .icon {
              margin: 0 3px 0 8px;
            }
          }
        }
      }
    }
    .Copyright {
      color: #fff;
      opacity: 0.5;
      justify-content: center;
      align-items: center;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-weight: 600;
    }
  }
}
