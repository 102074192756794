.Btn {
  height: 100%;
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 90%;
  width: 100%;
  margin: 0 auto;
  --border-radius: 12px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}

.countryCard {
  width: 100%;
  border-radius: 10px;
  margin: 0;
  ion-card-content {
    height: 100%;
    padding: 0;
    ion-item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      --padding-start: 10px;
      --inner-padding-end: 0;
      ion-label {
        display: flex !important;
        align-items: center !important;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif !important;
        font-size: 0.9em !important;
        font-weight: bold !important;
        --color: #0f324d96 !important;
      }
      ion-textarea {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
        font-size: 1em;
        padding: 0.2%;
        --placeholder-color: rgba(0, 0, 0, 0.7);
      }
      ion-input {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
        font-size: 1em;
        padding: 0.2%;
        --placeholder-color: rgba(0, 0, 0, 0.7);
      }
      ion-select::part(text) {
        display: none;
      }
    }
  }
}

.inputCard {
  border-radius: 10px;
  margin: 0;
  ion-card-content {
    height: 100%;
    width: 100%;
    padding: 0;
    ion-item {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      --padding-start: 10px;
      --inner-padding-end: 0;

      ion-input {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
        font-size: 1em;
        padding: 1%;
        --placeholder-color: rgba(0, 0, , 0.7);
      }
      ion-textarea {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
          Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
          sans-serif;
        font-size: 1em;
        padding: 1%;
        --placeholder-color: rgba(0, 0, , 0.7);
        height: 100px;
        padding: 10px;
      }

      .input-button {
        --padding-start: 0;
        --padding-end: 0;
        .input-icon {
          font-size: 1.5rem;
        }
      }
    }
  }
}

.checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  --background: transparent;
  ion-label {
    font-weight: bold;
  }
}

.auth-layout {
  width: 100%;
  height: auto;
  .page-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .content-wrapper {
      flex: 1 1 auto;
      height: auto;
    }
    .auth-footer {
      width: 100%;
      height: auto;
    }
  }
}

.authlayout-nav {
  width: 100%;
  height: 10dvh;
}
