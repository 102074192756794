.container {
  overflow-x: scroll;
  overflow-y: hidden;
  .swiper {
    height: 100%;
  }
  img {
    height: 100%;
    object-fit: cover;
  }
}
.imageWrapper {
  height: 100%;
  width: 100%;
  background-color: var(--ion-color-primary-tint);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  p {
    font-size: 1rem;
    color: white;
    margin: 0;
    text-shadow: 0.5px 0.5px var(--ion-color-secondary-tint);
  }
}