$font: 'Roboto', sans-serif;

.Btn{
  height:6vh;
 fill: solid;
}

.desktop,
.mobile {
  width: 100%;
  height: 100%;
  display : flex;
  justify-content : center;
  align-items : center;

section {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  
  }
  form{
    h2{
      text-align: center;
      font-weight: bold;
      color: #0f324d;
      margin: 50px 0 20px 0;
    }
    a{
      justify-content: center;
      display: flex;
      align-items: center;
      text-decoration: none;
      }
    .error{
      color: red !important
    }
    p{
      text-align: center;
      font-weight: bold;
      color: #0f324d;
    }
}

}

.desktop {
  form {
    width: 40%;
  }
}
