.signup-page {
  .title-wrapper {
    h1 {
      text-align: center;
      color: var(--ion-color-primary);
      font-weight: bold;
    }
  }

  .form-wrapper {
    form {
      width: 100%;
      padding: 0 20px 20px 20px;
      ion-row {
        ion-col {
          display: flex;
          flex-direction: column;
        }
        .field-col {
          margin: 5px 0;
        }
      }
      .submit-btn-row {
        margin-top: 10px;
      }
      .login-row {
        ion-col {
          justify-content: center;
          p {
            text-align: center;
            margin: 5px 0;
            color: var(--ion-color-primary);
            a {
              color: var(--ion-color-secondary);
            }
          }
        }
      }
			.error {
				color: red;
			}
    }
  }
}
