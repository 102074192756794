.header{
    background-color: white;
    padding-top: 10px;
    padding-left: 30px;
    padding-bottom: 10px;
    border-radius: 10px;
    margin: 15px;
    height: 150px;
    .error{
        color: red;
        text-align: right;
        padding-right: 15px;
        font-size: 12px;
     }
    .sec{
        // line-height: 5px;
    .arrow{
        font-size: 30px;
    }
       .heading{
        color:#0A2B45;
        font-weight: 600;
        font-size: 25px;
    }
}
    .buttons{
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
        .spinner{
            color: white;
            width: 20px;
            padding-top: 35px;
        }
        .sec1{
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 15px;
        .status{
        color:#0A2B45;
        font-weight: 600;
        font-size: 20px;
        }
        .pend{
            width: 120px;
            height: 35px; 
            border-radius: 3px;
            font-weight: 600;
            font-size: 13px;
            line-height: 25px;
            text-transform: uppercase;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #FFE9D2;
            color: #D68800;
        }
    } 
       .sec2{
            display: flex;
            flex-direction: row;
        .approve{
            width: 160px;
            height: 35px;
            background-color: #28A745;
            border-radius: 8px;
            color: white;
            font-weight: 600;
            margin-left: 10px;
            font-size: 13px;
            line-height: 25px;
         }
         .reject{
             background-color: #E53A34;
             width: 160px;
             height: 35px;
             border-radius: 8px;
             color: white;
             font-weight: 600;
             margin-left: 10px;
             font-size: 13px;
             line-height: 25px;
             
         }
       }
    }
}
.main{
    height: 80vh;
    overflow-y: auto;
    background-color: white;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
    padding: 20px;
    p{
        color: #0A2B45;
        font-size: 18px;
        margin-top: 15px;
        }
    .details{
        display: flex;
        margin-top: 30px;
        padding-left: 50px;
        .para3{
            width: 100%;
        }
        .para2{
            width: 70%;
            p{
                font-weight: 600;
                margin-top: 15px;

                }
        }
    }
    .banner{
        margin-left: 25px;
        margin-top: 30px;
        display: flex;
        gap: 20px;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        img{
            width: 300px;
            max-height: 300px;
            border-radius: 10px;
            object-fit: cover;
        }
        .noImage{
            background-color:#8080804d;
            width: 90%;
            height: 300px;
            border-radius: 10px;
        }
    }
}

@media screen and (max-width: 480px) {
    .header{
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 15px;
        height: 200px;
        .sec{
            .arrow{
                font-size: 20px;
            }
           .heading{
            font-size: 20px;
        }
    }
        .buttons{
            display: block;
            padding-left: 0px;
            .spinner{
                width: 15px;
                padding-top: 15px;
            }
            .sec1{
                gap: 5px;
                .pend{
                    width: 80px;
                    height: 25px; 
                    font-size: 10px;
                }
                .status{
                    font-size: 15px;
                    }
            }
            .sec2{
                 display: flex;
                justify-content: end;
                margin-top: 25px;
                .approve{
                    width: 100px;
                    height: 25px;
                    font-size: 10px;
                 }
                 .reject{
                     width: 100px;
                     height: 25px;
                     font-size: 10px;
                     
                 }
        }
      }
    }
    .main{
        height: 70vh;
        overflow-y: auto;
        p{
            font-size: 14px;
            }
        .details{
            width: 100%;
            padding-left: 10px;
            .para2{
                width: 100%;
                p{
                    font-size: 14px;
                    }
            }
        }
     .banner{
        margin-left: 0px;
        margin-top: 20px;
        img{
            width: 100%;
            height: 200px;
        }
        .noImage{
            width: 100%;
            height: 150px;
        }
    }
    }
}