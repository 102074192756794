@import '../../../utils/utils.scss';

.mainHolder {
  width: 100%;
  height: 100%;
  .storeGrid {
    padding: 0;
    width: 100%;
    height: 100%;
    ion-row {
      width: 100%;
      ion-col {
        height: 100%;
        padding: 0;
      }
    }
    .topBar {
      width: 100%;
      height: auto;
    }
    .main {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: row;
      align-items: center;

      .home,
      .items,
      .about,
      .search {
        position: relative;
        width: 100%;
        min-height: 50vh;
        align-items: center;
      }
    }
  }
}

.storeHead {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .infoBar {
    position: relative;
    width: 100%;
    overflow: hidden;
    .headImg {
      position: relative;
      width: 100%;
      height: 65%;
      background: rgb(0, 0, 0);
      .overlay {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 0.05),
          rgba(255, 255, 255, 0.1),
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.3),
          rgba(255, 255, 255, 0.5),
          rgba(255, 255, 255, 0.6),
          rgba(255, 255, 255, 1)
        );
      }
      .img {
        position: relative;
        width: 100%;
        height: 100%;
        background: #0f324d;
        img {
          position: relative;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .profile {
      position: absolute;
      width: 95%;
      height: 55%;
      top: 40%;
      z-index: 1;
      left: 2.5%;
      border-radius: 10px;
      background: white;
      border: solid 0.1px rgba(192, 188, 188, 1);
      @media screen and (max-width: 768px) {
        height: 75%;
        top: 23%;
      }
      .infogrid {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        padding: 1% 2%;
        @media screen and (max-width: 768px) {
          flex-direction: column;
          padding: 1%;
        }

        .profileInfo {
          position: relative;
          display: flex;
          width: 60%;
          height: 100%;
          align-items: center;
          justify-content: space-evenly;
          @media screen and (max-width: 768px) {
            width: 100%;
            height: 35%;
          }
          .profileImg {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            @media screen and (max-width: 768px) {
              width: 40%;
              padding: 6% 3%;
            }
            @media screen and (min-width: 768px) {
              width: 30%;
              padding: 0 0.5%;
            }

            div {
              background: white;
              border-radius: 50%;
              width: 100%;
              height: 100%;
              overflow: hidden;
              border: 1px solid #0f324d;
              img {
                position: relative;
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
          .storeInfo {
            display: flex;
            flex-direction: column;
            justify-content: start;
            width: 55%;
            @media screen and (max-width: 768px) {
              width: 58%;
            }
            @media screen and (min-width: 768px) {
              width: 60%;
            }
            height: auto;
            .name {
              display: flex;
              align-items: center;
              @include typography-primary(1.6em, bold, #0f324d);
            }
            .type {
              display: flex;
              align-items: center;
              p {
                display: flex;
                align-items: center;
                margin: 0;
                padding-inline-start: 2px;
                @include typography-primary(small, bold, rgba(0, 0, 0, 0.79));
              }
            }
          }
        }

        .contacts {
          position: relative;
          display: flex;
          width: 60%;
          height: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: space-evenly;
          @media screen and (max-width: 768px) {
            padding: 1% 0;
            align-items: flex-start;
            width: 100%;
            height: auto;
            min-height: 25%;
            flex-direction: row;
          }

          .locs {
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 40%;
            height: auto;
            @media screen and (min-width: 768px) {
              width: 50%;
              margin: 0 0 0 auto;
            }

            .icon {
              position: relative;
              height: 100%;
              width: 15%;
              display: flex;
              justify-content: center;
            }
            .locations {
              width: 80%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              .item {
                margin-bottom: 3px;
                @include typography-primary(1em, bold, rgba(0, 0, 0, 0.5));
              }
            }
          }
          .tel {
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 40%;
            height: auto;
            @media screen and (min-width: 768px) {
              width: 50%;
              margin: 0 0 0 auto;
            }
            .icon {
              position: relative;
              height: 100%;
              width: 15%;
              display: flex;
              justify-content: center;
            }
            .phoneNumber {
              width: 80%;
              height: 100%;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              .item {
                margin-bottom: 3px;
                @include typography-primary(1em, bold, rgba(0, 0, 0, 0.5));
              }
            }
          }
        }
        .bio {
          width: 100%;
          min-height: 20%;
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0 1%;
          .label {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            width: 100%;
            height: 100%;
            padding: 2% 0;
          }
          .about {
            @include typography-primary(1em, bold, rgba(15, 50, 77, 0.768));
          }
        }
      }
    }
  }
}
.drawer {
  position: relative;
  width: 100%;
  height: 15%;
  margin-top: 5px;
  padding: 0 2%;

  .holder {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    border-bottom: 1px solid rgba(15, 50, 77, 0.7);
    .segment {
      width: 65%;
      height: 100%;
      padding: 0 2%;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: 768px) {
        width: 90%;
      }
    }
    .search {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      
      @media screen and (max-width: 768px) {
        width: 100%;
        padding: 8px 2px;
      }
      .searchfieldMobile {
        width: 100%;
        height: 100%;
        background: white;
        border-radius: 10px;
        border: solid 0.1px rgba(192, 188, 188, 1);
      }
      .searchfieldDesktop {
        position: relative;
        width: 90%;
        height: 70%;
        margin-left: auto;
        background: white;
        border-radius: 50px;
        padding-inline-start: 2%;
        border: solid 0.1px rgba(192, 188, 188, 1);
      }
      .searchbtn {
        position: relative;
        width: 60px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.productsWrapper {
  width: 100%;
  height: 100%;
  .grid {
    padding: 0;
    width: 96%;
    margin: 0 auto;
    height: 100%;
    padding: 0 1%;
    min-width: 75%;
    border: none;
    outline: none;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
    @include typography-primary(1em, medium, rgba(15, 50, 77, 1));
  }
  input:focus {
    border-bottom: 1px solid #0f324d;
  }
  .searchbtn {
    position: relative;
    height: 100%;
    width: 25;
    border-radius: 50px;
    cursor: pointer;
    background: #A94064;
    color: white;
  }
}



