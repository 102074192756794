@import '../../../../utils/utils.scss';

.sidenav_grid {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 0 3%;
  ion-row {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 4px 0;
    ion-col {
      display: flex;
      align-items: center;
      padding: 0;
      margin: 0;
    }
  }
  .logo {
    width: 100%;
    height: 7dvh;
    ion-col{
      display: flex;
      justify-content: space-between;
    }
    .holder {
      height: 100%;
      display: flex;
      align-items: center;
      width: auto;
      cursor: pointer;
      ion-icon {
        height: 100%;
        aspect-ratio: 1/1;
        width: auto;
        margin-right: 5px;
      }
      span {
        @include typography-primary(1.2em, 600, var(--ion-color-primary));
        width: fit-content;
      }
      .span2 {
        color: var(--ion-color-secondary);
        font-style: italic;
        padding-inline-start: 2px;
      }
      .span3 {
        margin-left: 7px;
        padding-inline-start: 3px;
        border-left: 2px solid var(--ion-color-primary);
      }
    }
  }
  .admin_bar {
    width: 100%;
    height: 10dvh;
    .adminbar_chip {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 3%;
      background-color: rgba(10, 43, 69, 0.1);
      .user {
        height: 100%;
        width: auto;
        aspect-ratio: 1/1;
        background-color: white;
        border-radius: 10px;
        padding: 4%;
        ion-icon {
          height: 100%;
          width: auto;
          aspect-ratio: 1/1;
        }
      }
      ion-label {
        text-transform: capitalize;
        padding-inline-start: 5%;
        @include typography-primary(1.2em, 600, rgba(10, 43, 69));
      }
    }
  }
  .main_links,
  .approval_links,
  .bottom_links {
    ion-list {
      width: 100%;
      height: auto;
      .link:hover {
        background-color: rgba(15, 50, 77, 0.04);
      }
      .link {
        width: 100%;
        height: 6dvh;
        margin: 3px 0;
        padding: 2% 5%;
        display: flex;
        justify-content: start;
        position: relative;
        border-radius: 10px;
        cursor: pointer;
        ion-icon {
          height: 100%;
          width: auto;
          aspect-ratio: 1/1;
        }
        span {
          @include typography-primary(1.1em, 600, unset);
          padding-inline-start: 5px;
        }
      }
    }
  }

  .main_links {
    width: 100%;
    height: fit-content;
  }
  .approval_links {
    width: 100%;
    height: fit-content;
    ion-list {
      border-top: 1px solid rgba(15, 50, 77, 0.4);
      ion-list-header:hover {
        background-color: rgba(15, 50, 77, 0.03);
      }

      ion-list-header {
        height: 3dvh;
        width: 100%;
        position: relative;
        overflow: hidden;
        border-radius: 10px;
        cursor: pointer;
        ion-label {
          text-transform: uppercase;
          @include typography-primary(1em, 600, rgba(10, 43, 69, 0.7));
        }
        .btn {
          height: 100%;
          width: auto;
          aspect-ratio: 1/1;
          padding: 4.5%;

          ion-icon {
            height: 100%;
            width: auto;
            aspect-ratio: 1/1;
          }
        }
      }
      .link {
        padding-inline-start: 15%;
      }
    }
  }
  .bottom_links {
    width: 100%;
    height: fit-content;
    margin-top: auto;
  }
}
