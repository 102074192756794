@import '../../../../utils/utils.scss';

.container {
  width: 100%;
  height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .main {
    position: relative;
    height: 70%;
    width: 100%;
    padding: 0;
    ._holder {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 0.2em;
      
      ion-list {
        position: relative;
        width: 100%;
        height: 100%;

        .btn {
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          justify-content: start;
          margin: 7% 0;
          .btn_icon {
            position: relative;
            aspect-ratio: 1/1;
            width: 20%;
            height: auto;
          }
          ion-label {
            width:80%;
            @include typography-primary(1.1em, bold, var(--ion-color-primary));
          }
        }
      }
    }
  }
  .last {
    height: 30%;
    ion-col {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0 0.2em;
      ion-list {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        .btn {
          width: 100%;
          height: 30%;
          display: flex;
          align-items: center;
          justify-content: center;

          .btn_icon {
            position: relative;
            aspect-ratio: 1/1;
            width: 20%;
            height: auto;
          }
          ion-label {
            width:80%;
            @include typography-primary(1.1em, bold, var(--ion-color-primary));
          }
        }
      }
    }
  }
}
