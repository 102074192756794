.container {
  width: 100%;
  height: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  ion-grid {
    width: 100%;
    height: 100%;
    ion-row {
      width: 100%;
      padding: 0;
      ion-col {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .desktop {
      height: 100%;
      width: 100%;
      padding: 0;
      .one {
        height: 100%;
        display: inline;
        .logo {
          width: 100%;
          height: 10%;
          display: flex;
          align-items: center;
          padding-inline-start: 30px;
          font-size: 1.8em;
          font-weight: 600;
          color: #0f324d;
          font-family: Roboto, sans-serif;
        }
        .svg {
          width: 80%;
          height: 80%;
          margin: 0 auto;
          overflow: hidden;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .two {
        height: 100%;
        display: block;
        .top {
          width: 100%;
          height: 10%;
          display: flex;
          align-items: center;
          justify-content: end;
          padding-inline-end: 20px;

          ion-button {
            --border-radius: 20px;
            width: 20%;
            font-weight: bold;
          }
        }
        .code {
          width: 100%;
          height: 40%;
          display: flex;
          font-size: 10em;
          font-weight: 600;
          color: #0f324d;
          font-family: Roboto, sans-serif;
          margin: 2% auto;
          align-items: end;
        }
        .info {
          width: 100%;
          height: 20%;
          h3 {
            font-size: 2em;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
            font-family: Roboto, sans-serif;
            margin: 2% 0;
          }
          p {
            font-size: 1em;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            margin: 0;
            font-family: Roboto, sans-serif;
            padding-inline-end: 10%;
          }
        }
        .btn {
          width: 100%;
          height: 10%;
          display: flex;
          align-items: center;
          justify-content: start;
          ion-button {
            --border-radius: 20px;
          }
        }
      }
    }
    .mobile {
      height: 100%;
      width: 100%;
      padding: 0;
      ion-col {
        display: inline-block;
        height: 100%;
        div {
          width: 100%;
          display: flex;
          align-items: center;
        }
        .head {
          height: 10%;
          justify-content: space-between;
          .logo {
            width: 70%;
            height: 100%;
            display: flex;
            align-items: center;
            padding-inline-start: 10px;
            font-size: 1.5em;
            font-weight: 600;
            color: #0f324d;
            font-family: Roboto, sans-serif;
          }
          ion-button {
            --border-radius: 20px;
            width: 30%;
          }
        }
        .svg {
          height: 40%;
          margin: 3% auto;
          overflow: hidden;
          justify-content: center;
        }
        .code {
          height: 15%;
          font-size: 5em;
          font-weight: 600;
          color: #0f324d;
          font-family: Roboto, sans-serif;
          margin: 2% auto;
          justify-content: center;
        }
        .info {
          display: inline-block;
          height: 13%;
          text-align: center;
          h3 {
            font-size: 2em;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.9);
            font-family: Roboto, sans-serif;
            margin: 2% 0;
          }
          p {
            font-size: 1em;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.9);
            margin: 0 5%;
            font-family: Roboto, sans-serif;
          }
        }
        .btn {
          height: 10%;
          justify-content: center;
          ion-button {
            --border-radius: 10px;
          }
        }
      }
    }
  }
}
