@import '../../utils//utils.scss';

.inputCard {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0;
  ion-card-content {
    height: 100%;
    width: 100%;
    padding: 0;
    ion-item::part(native) {
      width: 100%;
      height: 100%;
    }
    ion-item {
      --padding-start: 10px;
      --inner-padding-end: 0;

      ion-input {
        @include typography-primary(1em, unset, unset);
        align-items: center;
      }
      ion-textarea {
        height: 100%;
        width: 100%;
        @include typography-primary(1em, unset, unset);
        align-items: flex-start;
        padding: 1%;
      }
      ion-textarea {
        width: 100%;
        margin: 0;
        padding: 0;
        .textarea-wrapper {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
