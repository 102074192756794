.grid{
    --ion-grid-width-xl: 1800px;
     --ion-grid-width-lg: 1200px;
    --ion-grid-width-md: 900px;
    --ion-grid-width-sm: 720px;
    --ion-grid-width-xs: 540px;
   }
.desktop{
    width: 100%;
    height: 100%;
    
.flex{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}
}
.type{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;

}