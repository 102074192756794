@import '../../utils/utils.scss';

.main {
  width: 100%;
  height: 100%;
  position: relative;
  .card {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 1px solid #0f324d6a;
    display: flex;
    flex-direction: column;
    padding: 2%;
    .label {
      width: 100%;
      height: 10%;
      margin: 2px 0;
      @include typography-primary(small, bold, #0f324d);
    }
    .info {
      width: 100%;
      height: 90%;
      display: flex;
      align-items: center;
      padding: 2% 0;
      .profile {
        display: flex;
        height: 100%;
        aspect-ratio: 1/1;
        width: auto;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #0f324d5b;

        .img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .name {
        height: 100%;
        width: auto;
        margin: 0 5px;
        @include typography-primary(1em, bold, #0f324d);
      }
      .btn {
        margin-left: auto;
        ion-button {
          --box-shadow: 0;
        }
      }
    }
  }
}
