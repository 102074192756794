form{
  width: 90%;             
.button{
  width: 143px;
  height: 55px;   
}
.message{
  color: green;
  font-size: 14px;
  width: 100%;
}
.error{
  color: red;
}
}

