@import '../../../utils/utils.scss';

.wishlistContainer {
	position: relative;
	width: 100%;
	height: auto;
	padding: 0;
	ion-row {
		width: 100%;
		ion-col {
			height: 100%;

			.head {
				width: 100%;
				height: 5.5vh;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 0 2%;
				.title {
					width: auto;
					display: flex;
					align-items: center;
					@include typography-primary(1.2em, bold, #0f324d);
				}
			}

			.content {
				width: 100%;
				height: auto;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				flex-direction: row;
				flex-wrap: wrap;
				padding: 2% 1%;
			}
		}
	}

	.no-favorites {
		text-align: center;
		margin: 0 auto;
		font-size: 1.2rem;
	}
}
