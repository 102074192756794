ion-grid {
	width: 100%;
	height: 100%;
	padding: 0;
	position: relative;
	ion-row {
		ion-col {
			width: 100%;
			height: 100%;

			.title {
				font-size: 7rem;
				font-weight: bold;
				margin-bottom: 40px;
				color: #0a2b45;
				span {
					color: #a94064;
					font-size: 5rem;
					display: block;
				}
			}
      .sm-title {
        font-size: 5rem;
        margin-top: 0;
        span {
          font-size: 4rem;
        }
      }
		}
	}

	.title-row {
		min-height: 250px;
		padding: 20px 50px 0 50px;
		h1 {
			display: inline-block;
		}
		.image {
			width: 25%;
			position: absolute;
			right: 0;
			top: 0;
		}
    .md-img {
      width: 20%;
    }
    .lg-img {
      width: 15%;
    }
	}
	.intro-row {
		padding: 20px 50px;
		background: #0a2b45;
		color: white;
	}

	.feature-row {
		padding: 50px;
		.feature-title-wrapper {
			display: flex;
			align-items: center;
			.box {
				background: #a94064;
				border-radius: 10px;
				width: 50px;
				height: 50px;
				margin-right: 10px;
			}
			h6 {
				font-size: 1.2rem;
				font-weight: bold;
				color: #0a2b45;
				margin: 0;
			}
		}
		.feature-info {
			font-size: 1rem;
		}
	}
	.support-row {
		padding: 20px 50px;
    h6 {
      font-weight: bold;
      color: #0a2b45;
    }
	}
	.contact-us-row {
		padding: 20px 50px;

		h4 {
			font-size: 1.5rem;
			font-weight: bold;
			color: #0a2b45;
			text-align: center;
		}
	}
  .sm-padding {
    padding: 20px 30px;
  }
  .sm-title-padding {
    padding-left: 30px;
    padding-right: 30px;
  }
  form {
    width: 100%;
  }
}
