@import '../../utils/utils.scss';

._card {
  .loading {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

  ._title,
  ._slug,
  ._description {
    div {
      @include typography-primary(unset, unset, var(--ion-color-primary));
    }
  }
}

.listItem {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .name {
    width: 70%;
    height: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    font-size: 0.9em;
    font-family: Roboto, sans-serif;
    .input {
      position: relative;
      width: 100%;
      height: 100%;
      ion-input {
        position: relative;
        width: 100%;
        height: 100%;
        --padding-top: 0;
        --padding-start: 0;
        --padding-bottom: 0;
        --padding-end: 0;
        border-bottom: 1px solid rgba(15, 50, 77, 0.3);
        color: #0f324d;
      }
    }
  }
  .icons {
    display: flex;
    height: 100%;
    width: 30%;
    div {
      position: relative;
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    .one {
    }
    .two {
    }
  }
}

.backdrop::before {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  display: block;
  cursor: default;
  content: ' ';
  background: transparent;
  grid-column: 1 / 1;
}
.backdrop:focus {
  outline: none;
  box-shadow: none;
}
.backdrop {
  display: flex;
  align-items: center;
  justify-content: center;
}
.LocCard {
  height: 25vh;
  width: 50vh;
  grid-column: 1 / 1;
  position: absolute;
  z-index: 200;
  ion-card-content {
    width: 100%;
    height: 100%;
    padding: 0.5em 1em;
    ._container {
      padding: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      ion-label {
        font-family: Roboto, sans-serif;
        font-size: 0.9em;
        font-weight: bold;
      }
      ion-input {
        --padding-top: 0;
        --padding-start: 0;
        --padding-bottom: 0;
        --padding-end: 0;
        border-bottom: 1px solid rgba(15, 50, 77, 0.3);
        color: #0f324d;
      }
      ion-row,
      ion-col {
        position: relative;
        padding: 0;
      }
      ._name {
        width: 100%;
        height: 33%;
        overflow: hidden;

        ion-col {
          display: flex;
          align-items: center;
          .text {
            width: 85%;
            height: 100%;
            padding-inline-start: 2%;
            padding-inline-end: 2%;
          }
          ._icon {
            position: relative;
            width: 15%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            ion-icon {
              font-size: 1.5em;
            }
          }
        }
      }
      ._coordinates {
        width: 100%;
        height: 33%;
        ion-col {
          display: flex;
          div {
            width: 50%;
            height: 100%;
            padding-inline-start: 2%;
            padding-inline-end: 2%;
          }
        }
      }
      ._btn {
        width: 100%;
        height: 33%;
        ion-col {
          display: flex;
          justify-content: flex-end;
          div {
            display: flex;
            justify-content: center;
            width: 30%;
            height: 100%;
          }
          .one {
            padding: 0.5em 0;
            ion-button {
              height: 100%;
              display: flex;
              align-items: center;
              margin: 0;
            }
          }
          .two {
            padding: 0.5em 0;
            ion-button {
              height: 100%;
              display: flex;
              align-items: center;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
