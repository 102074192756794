@import '../../../../utils/utils.scss';

.desktop {
  height: 100%;
  width: 100%;
  .holder {
    height: 100%;
    width: 100%;
    ion-row {
      width: 100%;
      height: 100%;
      ion-col {
        height: 100%;
        padding: 0;
      }
      .logo {
        padding-inline-start: 15px;
        .holder {
          height: 100%;
          display: flex;
          align-items: center;
          width: auto;
          cursor: pointer;

          span {
            @include typography-primary(1.3em, 600, white);
            width: fit-content;
          }
          .span2 {
            color: var(--ion-color-secondary);
            font-style: italic;
          }
        }
      }
      .navlinks {
        height: 100%;
        margin-left: auto;

        ion-row {
          padding-inline-end: 10px;
          justify-content: end;
          .navItem:hover {
            color: var(--ion-color-secondary-tint) !important;
          }
          .navItem {
            height: 100%;
            cursor: pointer;
            @include typography-primary(1.1em, bold, 8);
            justify-content: center;
          }
        }
      }
      .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .holder {
          width: 90%;
          height: 75%;
        }
      }
      .storeMenu {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .chip {
          display: flex;
          width: 8vw;
          height: 100%;
          align-items: center;
        }
        .btnGrp {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          ion-buttons {
            width: 100%;
            height: 100%;
            justify-content: space-evenly;
            ion-button {
              font-weight: bold;
            }
            .two {
              width: 7vw;
              --border-radius: 5px;
            }
          }
        }
      }
    }
  }
}

.mobile {
  height: 100%;
  width: 100%;

  .holder {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0 3%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e2e3e5;
    .logo {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 4% 0;
      width: auto;
      ion-icon {
        height: 100%;
        aspect-ratio: 1/1;
        width: auto;
        margin: 0;
      }
      span {
        @include typography-primary(1.1em, 600, white);
        width: fit-content;
      }
      .span2 {
        color: var(--ion-color-secondary);
        font-style: italic;
      }
    }
    .chip {
      width: auto;
      aspect-ratio: 1/1.5;
      height: 100%;
      display: flex;
      align-items: center;
      align-self: flex-end;
      margin-left: auto;
      margin-right: 5%;
    }
    .btnGrp {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      align-self: flex-end;
      margin-left: auto;
      ion-button {
        font-weight: bold;
      }
      .btn {
        --border-radius: 5px;
        font-weight: bold;
        font-size: 1em;
      }
    }
    .menu {
      height: 100%;
      aspect-ratio: 1/1.7;
      width: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4% 0;
      width: auto;
      ion-icon {
        height: 100%;
        aspect-ratio: 1/1;
        width: auto;
      }
    }
  }
}
